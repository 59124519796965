<template>
  <div class="home-page">
    <div class="db-card">
      <div class="card-title">我的待办</div>
      <div class="card-content">
        <div class="db-item" @click="go('ys', item)" v-for="item in budgetYearUpcoming" :key="'ys' + item.id + '-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-yusuan"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            {{item.year + '年' + (item.type === 'type' ? '追加' : '') + '预算'}}
          </div>
          <div class="db-role">
            {{item.orgRoleList.map(v => orgRoleMap[v.orgId + '_' + v.roleId]).join('，')}}
          </div>
        </div>
        <div class="db-item" @click="go('cg', item)" v-for="item in purchaseBatchUpcoming" :key="'cg' + item.id + '-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-caigou"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            {{item.year + '年采购(' + item.batch + ')'}}
          </div>
          <div class="db-role">
            {{item.orgRoleList.map(v => orgRoleMap[v.orgId + '_' + v.roleId]).join('，')}}
          </div>
        </div>
        <div class="db-item" @click="go('cgfs', item)" v-for="item in purchaseMethodBatchUpcoming" :key="'cgfs' + item.id + '-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-caigoufangshi"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            {{item.year + '年采购方式(' + item.batch + ')'}}
          </div>
          <div class="db-role">
            {{item.orgRoleList.map(v => orgRoleMap[v.orgId + '_' + v.roleId]).join('，')}}
          </div>
        </div>
        <div class="db-item" @click="go('yanshou', item)" v-for="item in acceptanceUpcoming" :key="'ys-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-cheliangleixing"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            资产验收
          </div>
          <div class="db-role">
            {{item.orgRoleList.map(v => orgRoleMap[v.orgId + '_' + v.roleId]).join('，')}}
          </div>
        </div>
        <div class="db-item" @click="go('wx', item)" v-for="item in repairUpcoming" :key="'wx-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-install"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            维修申请
          </div>
          <div class="db-role">
            {{item.orgRoleList.map(v => orgRoleMap[v.orgId + '_' + v.roleId]).join('，')}}
          </div>
        </div>
        <div class="db-item" @click="go('bf', item)" v-for="item in scrapUpcoming" :key="'bf-' +item.roleId">
          <div class="db-top">
            <i class="iconfont icon-right-rotate"></i>
            <div class="db-num">{{item.upcomingCount}}条待办</div>
          </div>
          <div class="db-title">
            报废申请
          </div>
          <div class="db-role">
            {{item.orgRoleList.map(v => orgRoleMap[v.orgId + '_' + v.roleId]).join('，')}}
          </div>
        </div>
      </div>
    </div>
    <fm-modal v-model="open" title="请选择以什么角色处理代办" width="730">
      <div class="r-c">
        <div class="item" @click="goPage(item.roleId, item.orgId)" v-for="item in orgRoleList" :key="item.roleId + '_' + item.orgId">
          <div class="label">{{orgRoleMap[item.orgId + '_' + item.roleId]}}</div>
        </div>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import {
  budgetYearRequest,
  purchaseBatchRequest,
  purchaseMethodBatchRequest,
  acceptanceRequest,
  assetsRepairApplyRequest,
  assetsScrapApplyRequest
} from '../api'

import {
  localstorageHelper
} from '@/fmlib'

const mergeData = (data) => {
  let dataMap = {}
  data.forEach(v => {
    let key = v.id + '_' + v.upcomingCount
    if (!dataMap[key]) {
      dataMap[key] = v
      v.orgRoleList = [{
        roleId: v.roleId,
        orgId: v.orgId
      }]
    } else {
      dataMap[key].orgRoleList.push({
        roleId: v.roleId,
        orgId: v.orgId
      })
    }
  })
  return Object.keys(dataMap).map(key => dataMap[key])
}

const mergeData2 = (data) => {
  let dataMap = {}
  data.forEach(v => {
    let key = v.upcomingCount
    if (!dataMap[key]) {
      dataMap[key] = v
      v.orgRoleList = [{
        roleId: v.roleId,
        orgId: v.orgId
      }]
    } else {
      dataMap[key].orgRoleList.push({
        roleId: v.roleId,
        orgId: v.orgId
      })
    }
  })
  return Object.keys(dataMap).map(key => dataMap[key])
}

export default {
  data () {
    return {
      open: false,
      budgetYearUpcoming: [],
      purchaseBatchUpcoming: [],
      purchaseMethodBatchUpcoming: [],
      acceptanceUpcoming: [],
      repairUpcoming: [],
      scrapUpcoming: [],
      rootParm: null,
      orgRoleList: []
    }
  },
  created() {
    this.loadData()
  },
  computed: {
    orgRoleMap () {
      let data = {}
      this.$store.getters.orgRoles.forEach(v => {
        data[String(v.orgId) + '_' + String(v.roleId)] = (v.orgName || '') + ' ' + (v.roleName || '')
      })
      return data
    },
    currentRoleId () {
      return this.$store.getters.currentRoleId
    },
    currentOrgId () {
      return this.$store.getters.currentOrgId
    }
  },
  methods: {
    async loadData () {
      let pList = []
      pList.push(budgetYearRequest.getUpcoming())
      pList.push(purchaseBatchRequest.getUpcoming())
      pList.push(purchaseMethodBatchRequest.getUpcoming())
      pList.push(acceptanceRequest.getUpcoming())
      pList.push(assetsRepairApplyRequest.getUpcoming())
      pList.push(assetsScrapApplyRequest.getUpcoming())
      this.budgetYearUpcoming = mergeData(await pList[0])
      this.purchaseBatchUpcoming = mergeData(await pList[1])
      this.purchaseMethodBatchUpcoming = mergeData(await pList[2])
      this.acceptanceUpcoming = mergeData2(await pList[3])
      this.repairUpcoming = mergeData2(await pList[4])
      this.scrapUpcoming = mergeData2(await pList[5])
      Promise.all(pList)
    },
    go (type, item) {
      let rootParm = null
      if (type === 'ys') {
        rootParm = {
          name: 'internalcontrol.budget.details', query: {
            id: item.id,
            type: 'handle'
          }
        }
      } else if (type === 'cg') {
        rootParm = {
          name: 'internalcontrol.purchase.details', query: {
            id: item.id,
            type: 'handle'
          }
        }
      } else if (type === 'cgfs') {
        rootParm = {
          name: 'internalcontrol.purchase_method.details', query: {
            id: item.id,
            type: 'handle'
          }
        }
      } else if (type === 'yanshou') {
        rootParm = {
          name: 'internalcontrol.accept.my'
        }
      } else if (type === 'wx') {
        rootParm = {
          name: 'internalcontrol.repair.apply'
        }
      } else if (type === 'bf') {
        rootParm = {
          name: 'internalcontrol.scrap.apply'
        }
      }
      this.rootParm = rootParm
      if (this.rootParm != null && item.orgRoleList && item.orgRoleList.length > 0) {
        if (item.orgRoleList.length === 1) {
          this.goPage(item.orgRoleList[0].roleId, item.orgRoleList[0].orgId)
        } else {
          this.orgRoleList = item.orgRoleList
          this.open = true
        }
      }
    },
    goPage (roleId, orgId) {
      if (Number(roleId) === this.currentRoleId && Number(orgId) === Number(this.currentOrgId)) {
        this.$router.push(this.rootParm)
      } else {
        let findOrgRole = this.$store.getters.orgRoles.find(v => Number(v.roleId) === Number(roleId) && Number(v.orgId) === Number(orgId))
        if (findOrgRole) {
          this.$store.dispatch('setOverviewRole', false)
          localstorageHelper.updateData('orgRoleId', findOrgRole.id)
          localstorageHelper.updateData('jumpPageParm', this.rootParm)
          window.location.reload()
        } else {
          this.$notice.info({ title: '当前角色异常'})
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.r-c {
  display: flex;
  justify-content: space-around;
  .item {
    flex: 1;
    text-align: center;
    padding: 20px 0;
    background: rgba(0,0,0,0.05);
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
}
.home-page {
  width: 100%;
  height: 100%;
}
.db-card {
  margin: 10px;
  background: #fff;
  padding: 20px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}
.card-title {
  font-size: 16px;
  color: #000;
  margin-bottom: 15px;
}
.card-content {
  height: calc(100% - 40px);
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.db-item {
  background-color: #fff;
  position: relative;
  min-width: 180px;
  cursor: pointer;
  padding: 10px;
  margin: 5px 10px 10px 5px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
  .db-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: 30px;
    }
  }
  .db-num {
    color: #fff;
    background: #F4628F;
    min-width: 20px;
    padding: 0 10px;
    margin-left: 10px;
    line-height: 20px;
    text-align: center;
    border-radius: 10px;
    height: 20px;
  }
  .db-title {
    color: #000;
    margin-bottom: 5px;
  }
  .db-role {
    color: rgba(50,60,71,.5);
    max-width: 13rem;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }
}
</style>